<template>
  <div class="overlay-loader">
    <Loading></Loading>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/Templates/Loading.vue"),
  },
};
</script>

<style scoped>
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
